/* Customize your main colors in :root variables */
:root {
  --main-background-color: #343A50;
  --card-background-color: #1e232d;
  --countdown-background-color: #433765;
  --main-text-color:#F7F6F4;
  --title-text-color:#e17c0e;
}

body{
/*  background-color:var(--main-background-color);*/
  background: #1e232d;
  background-size: cover;
  font-family: 'Brussels City'!important;
  font-size: 1.1em;
}

h2, p{
  color: var(--main-text-color);
}

h3{
font-weight: normal;
text-align: left;
margin-left: 20px;
}
.flex{
  display: flex;
  width: 80vw;
  justify-content: center;
  gap: 50px;
  align-items: center;
  margin: auto;
}
.flexinfo{
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}
.label{
  background: transparent!important;
  border: 2px solid white!important;
  border-radius: 6px!important;
  font-weight: 300;
  font-size: 30px !important;
  padding: 5px!important;
  width: 50%;
  font-family: 'Brussels City' !important;
}
.MuiChip-root{
  height: auto!important;
}
.title{
  font-size: 60px;
  margin: 0px;
}
.info{
  font-size: 22px;
  text-align: justify;
  margin-top: 0px;
}
@media(max-width: 800px){
  .flex{
    flex-direction: column-reverse;
  }
  .flexinfo{
    flex-direction: column;
    gap: 0px;
    margin-bottom: 20px;
  }
  .label{
    width: 95%;
  }
  img{
    max-width: 90vw;
  }
}

@font-face {
  font-family: 'Brussels City';
  font-style: normal;
  font-weight: normal;
  src: local('Brussels City Personal Used'), url('BrusselsCityPersonalUsed-L3Lon.woff') format('woff');
  }
